import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerV2 from "../components/elements/BannersV2/BannerV2"
import FormationContent from "../components/FicheFormation/"
import Event from "../components/Contact/event"
import Contact from "../components/Home/Contact"
import Thematiques from "../components/Formations/thematiques"

const FicheFormation = ({ data, location }) => {
  const {
    fiche_formation,
    evenement,
    formations,
    bloc_contact,
    articles,
  } = data

  const anchors = [
    // {
    //   content: "Problématiques",
    //   anchor: "agence-bloc-1",
    // },
    {
      content: "Détail de la formation",
      anchor: "detail-prestation",
    },
    // {
    //   content: "Notre accompagnement",
    //   anchor: "valeurs",
    // },
  ]

  const bannerLeftCardContent = {
    mainContent: fiche_formation.acf.texte,
    titleFirstLine: fiche_formation.acf.titre_1,
    titleSecondLine: fiche_formation.acf.titre_2,
    subTitle: fiche_formation.acf.sous_titre,
  }

  return (
    <Layout
      location={location.pathname.split("/")[1]}
      locationBis={`/${location.pathname.split("/")[1] +
        "/" +
        location.pathname.split("/")[2]}`}
      locationTer={location.pathname}
      crumbLabel="Nos Expertises"
      crumbLabelBis={fiche_formation.acf.secteur}
      crumbLabelTer={
        bannerLeftCardContent.titleSecondLine +
        " " +
        bannerLeftCardContent.titleFirstLine
      }
      locationGTM={location.href}
      noLocation
    >
      <SEO
        title={fiche_formation.acf.title}
        description={fiche_formation.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        anchors={anchors}
        hasTag
        hasBreadCrumb
        hasContactButton
        hasSlider
        isPrestationPage
        bannerLeftCardContent={bannerLeftCardContent}
        articles={articles.edges
          .filter(
            item =>
              fiche_formation.acf.secteur.toLowerCase() ===
              item.node.acf.theme_article.toLowerCase()
          )
          .slice(0, 3)}
      />
      <FormationContent
        body={fiche_formation.acf.contenu}
        avantages={fiche_formation.acf.avantages}
        formations={fiche_formation.acf.formations}
        contact={fiche_formation.acf.texte_contact}
        locationBack={
          location.pathname.split("/")[1] +
          "/" +
          location.pathname.split("/")[2]
        }
        ancreText={fiche_formation.acf.texte_ancre}
      />
      {evenement.acf.statut_evenement &&
        evenement.acf.statut_evenement === "Publié" && (
          <Event
            banner={
              evenement.acf.image_evenement.localFile.childImageSharp.fluid.src
            }
            titre={evenement.acf.titre_evenement}
            texte={evenement.acf.texte_evenement}
            date={evenement.acf.date_evenement}
            heure={evenement.acf.heure_evenement}
            adresse={evenement.acf.adresse_evenement}
            lieu={evenement.acf.lieu_evenement}
            lien={evenement.acf.lien_evenement}
          />
        )}
      <Thematiques
        title={fiche_formation.acf.titre_thematique}
        formations={formations.edges.filter(
          item => item.node.path !== fiche_formation.path
        )}
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
        id="contact-form"
      />
    </Layout>
  )
}

export default FicheFormation

export const query = graphql`
  query($id: String!, $theme: String!) {
    fiche_formation: wordpressWpFormations(id: { eq: $id }) {
      path
      acf {
        title
        meta_description
        metier_banniere
        secteur
        titre_1
        titre_2
        sous_titre
        texte
        titre_partie_1_banniere
        titre_partie_2_banniere
        texte_banniere
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        contenu
        avantages {
          avantage
        }
        formations {
          formation {
            duree_formation
            niveau_formation
            texte_formation
            titre_formation
          }
        }
        titre_thematique
        texte_contact
        texte_ancre
      }
    }
    evenement: wordpressPage(
      id: { eq: "412e0a3c-3d39-57c8-b08f-9e8b26826d0a" }
    ) {
      acf {
        adresse_evenement
        date_evenement
        heure_evenement
        lien_evenement
        lieu_evenement
        statut_evenement
        titre_evenement
        texte_evenement
        image_evenement {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
    formations: allWordpressWpFormations {
      edges {
        node {
          acf {
            titre_partie_1_banniere
            titre_partie_2_banniere
            texte_thematique_vignette
          }
          path
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
    articles: allWordpressPost(
      limit: 3
      filter: { acf: { theme_article: { eq: $theme } } }
      sort: { order: DESC, fields: modified }
    ) {
      edges {
        node {
          path
          acf {
            type_article
            theme_article
            titre_article
            intro_article
          }
        }
      }
    }
  }
`
