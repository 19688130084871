import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Parser from "html-react-parser"
import { Link } from "gatsby"

import globalVariables from "../globalVariables"
import Title from "../elements/Title"
import ButtonAnchor from "../elements/Anchor/ButtonAnchor"
import Horloge from "../../images/icons/horloge-bleu.svg"
import Expert from "../../images/icons/expert-bleu.svg"
import Debutant from "../../images/icons/debutant-bleu.svg"
import Fleche from "../../images/icons/fleche-blanche.svg"

const Section = styled.section`
  z-index: 1;
  position: relative;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 2rem;
  }
`

const Container = styled.div`
  max-width: 1095px;
  margin-right: auto;
  margin-left: auto;
`

const BackLink = styled(Link)`
  margin: 10px;
  position: absolute;
  top: -3rem;
  z-index: 2;
  color: white;
  text-decoration: none;
  &:visited,
  &:-webkit-any-link {
    color: white;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    top: -2.5rem;
  }
  span {
    transition: transform 0.2s ease-in-out;
    display: inline-block;
  }
  &:hover span {
    transform: translateX(0.5rem);
  }
`

const IconFleche = styled.img`
  width: 6px;
  height: 11px;
  margin-right: 5px;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }
`

const Align = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Card = styled.div`
  flex: ${props => (props.flex ? props.flex : null)};
  background: ${props =>
    props.color ? props.theme[props.color] : props.theme.greyLighter};
  margin: 0 0 20px;
  padding: ${props => (props.padding ? "30px 10px" : "0")};
  &.card-formation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: ${globalVariables.maxMobile}) {
    &.lesplus {
      margin: 0;
      border-bottom: 1px solid white;
    }
    &.contact-card {
      text-align: center;
      a {
        min-width: 220px;
      }
      p {
        margin-bottom: 2rem;
      }
    }
    &.button-contact {
      padding: 30px 10px;
      height: 143px;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : "0")};
    margin: 10px;
  }
`

const ContentBody = styled.div`
  h2 {
    color: ${props => props.theme.primary};
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  ul,
  ol {
    padding-bottom: 1rem;
    padding-left: 2rem;
    margin-left: 0;
  }
`

const List = styled.ul`
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const Item = styled.li`
  list-style: none;
  background: white;
  padding: 2px 5px;
  margin: 5px;
`

const Text = styled.p`
  font-size: 20px;
  font-weight: bold;
`

const AnchorStyled = styled(AnchorLink)`
  color: white !important;
  text-transform: uppercase;
  font-size: 22px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TitleFormation = styled.p`
  font-size: 22px;
  color: ${props => props.theme.primary};
  font-weight: bold;
  text-align: center;
`
const TextFormation = styled.p`
  text-align: center;
  padding: 0 15px;
`

const RowFormation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${props => (props.color ? props.theme[props.color] : null)};
  padding: ${props => (props.padding ? props.padding : null)};
  p {
    margin-bottom: 0;
    color: white;
    font-weight: bold;
  }
`

const Trait = styled.div`
  width: 3px;
  height: 16px;
  background: white;
  @media (min-width: ${globalVariables.minTablet}) {
    height: 28px;
  }
`

const LevelWrapper = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 15px;
  @media (max-width: ${globalVariables.maxMobile}) {
    display: none;
  }
`
const HorlogeStyled = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 15px;
  margin-right: 11px;
  @media (max-width: ${globalVariables.maxMobile}) {
    display: none;
  }
`

const Space = styled.div`
  padding: 40px 15px 24px;
`

const TextLevel = styled.p`
  margin-right: 15px;
`

const FormationContent = ({
  body,
  avantages,
  formations,
  contact,
  locationBack,
  ancreText,
}) => {
  return (
    <Section id="detail-prestation">
      <Container>
        <BackLink to={locationBack}>
          <IconFleche src={Fleche} alt="retour" /> <span>Retour</span>
        </BackLink>
        <Card padding="65px 35px 65px 45px">
          <ContentBody>{Parser(body)}</ContentBody>
        </Card>
        {avantages && (
          <Row>
            <Card flex="1 1 20%" className="lesplus" padding="40px 10px">
              <Align>
                <Title className="title1" color="primary" margin="0">
                  Les +
                </Title>
              </Align>
            </Card>
            <Card padding="20px 40px" flex="1 1 70%">
              <List>
                {avantages.map((item, index) => (
                  <Item key={index}>{item.avantage}</Item>
                ))}
              </List>
            </Card>
          </Row>
        )}
        {formations && (
          <Row>
            {formations.map((item, index) => (
              <Card flex="1 1 30%" className="card-formation" key={index}>
                <Space>
                  <TitleFormation>
                    {item.formation.titre_formation}
                  </TitleFormation>
                  <TextFormation>
                    {item.formation.texte_formation}
                  </TextFormation>
                </Space>
                <RowFormation color="secondary" padding="15px">
                  <RowFormation>
                    <LevelWrapper
                      src={
                        item.formation.niveau_formation === "Expert"
                          ? Expert
                          : Debutant
                      }
                      alt="niveau"
                    />
                    <TextLevel>
                      Niveau {item.formation.niveau_formation}
                    </TextLevel>
                  </RowFormation>
                  <Trait />
                  <RowFormation>
                    <HorlogeStyled src={Horloge} alt="temps de formation" />
                    <p>Durée : {item.formation.duree_formation}</p>
                  </RowFormation>
                </RowFormation>
              </Card>
            ))}
          </Row>
        )}
        <Row>
          <Card flex="1 1 70%" padding="40px 50px" className="contact-card">
            <Text>{contact}</Text>
            <ButtonAnchor
              anchor="contact-form"
              offset={globalVariables.navbarHeight}
              title="Nous contacter"
              colors="primaryWithBg"
            />
          </Card>
          <Card
            color="primary"
            flex="1 1 25%"
            className="button-contact"
            padding="40px 10px"
          >
            <AnchorStyled
              href="#contact-form"
              offset={globalVariables.navbarHeight}
            >
              {ancreText}
            </AnchorStyled>
          </Card>
        </Row>
      </Container>
    </Section>
  )
}

export default FormationContent
